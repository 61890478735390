import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BookingService } from 'src/app/services/booking.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { GridApi, GridOptions } from 'ag-grid-community';
import { CheckboxCellComponent } from '../checkbox-cell/checkbox-cell.component';
import * as moment from "moment";
import { EndDateCalculatorService } from 'src/app/services/endDateCalculator.service';
import { LanguageService } from 'src/app/services/language.service';



@Component({
  selector: 'app-book-class',
  templateUrl: './book-class.component.html',
  styleUrls: ['./book-class.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class BookClassComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private booking: BookingService,
    private toast: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private endDateCalculator:EndDateCalculatorService,
    // private languageService: LanguageService
  ) { 

    // const language = this.languageService.getLanguage();
  }
  startDate: Date;
  endDate: Date;
  calendarVisible = false;
  calenderOptions = null;
  teachersList = [];

  courseId = "";
  coursePackage = "";
  courseKey;
  courseCategory;
  course;
  columnDefs;
  rowData: any[] = [];
  showGrid = false;
  today = new Date();
  gridApi!: GridApi;
  gridOptions: GridOptions;
  selectedSchedule: any[] = [];
  selectedTeacher:any;
  sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  ngOnInit(): void {
    this.setEndDate(new Date())
    this.route.params.subscribe(async (params) => {
      this.courseId = params['courseId'];
      this.coursePackage = params['coursePackage']
    })
    this.spinner.show();
    this.getCourseDetails().then(async (res) => {
      // console.log("course details", res);
      this.courseCategory = res?.data.categoryKey;
      this.courseKey = res?.data.courseKey;
      this.course = res?.data.product_name;
      await this.sleep(5000);
    }).catch((err) => {
      console.error("Error in getting course details:", err.message);
    }).finally(() => {
      this.spinner.hide()
    });
    this.gridOptions = {
      onGridReady: this.onGridReady.bind(this),
      context: {
        componentParent: this
      },
      columnDefs: [
        {
          headerName: 'Sunday', field: 'sun', sortable: true, resizable: true,
          cellRendererFramework: CheckboxCellComponent,
          cellRendererParams: (params) => ({
            isChecked: false,
            value: params?.data?.sun || '',
            rowIndex: params?.rowIndex || 0,
            field: params?.colDef?.field || '',
            checkboxChange: this.onCheckboxChange.bind(this)
          }),
          cellClass: this.getCellClass.bind(this)
        },
        {
          headerName: 'Monday', field: 'mon', sortable: true, resizable: true,
          cellRendererFramework: CheckboxCellComponent,
          cellRendererParams: (params) => ({
            isChecked: false,
            value: params?.data?.mon || '',
            rowIndex: params?.rowIndex || 0,
            field: params?.colDef?.field || '',
            checkboxChange: this.onCheckboxChange.bind(this)
          }),

          cellClass: this.getCellClass.bind(this)
        },
        {
          headerName: 'Tuesday', field: 'tue', sortable: true, resizable: true,
          cellRendererFramework: CheckboxCellComponent,
          cellRendererParams: (params) => ({
            isChecked: false,
            value: params?.data?.tue || '',
            rowIndex: params?.rowIndex || 0,
            field: params?.colDef?.field || '',
            checkboxChange: this.onCheckboxChange.bind(this)
          }),


          cellClass: this.getCellClass.bind(this)
        },
        {
          headerName: 'Wednesday', field: 'wed', sortable: true, resizable: true,
          cellRendererFramework: CheckboxCellComponent,
          cellRendererParams: (params) => ({
            isChecked: false,
            value: params?.data?.wed || '',
            rowIndex: params?.rowIndex || 0,
            field: params?.colDef?.field || '',
            checkboxChange: this.onCheckboxChange.bind(this)
          }),

          cellClass: this.getCellClass.bind(this)
        },
        {
          headerName: 'Thursday', field: 'thu', sortable: true, resizable: true,
          cellRendererFramework: CheckboxCellComponent,
          cellRendererParams: (params) => ({
            isChecked: false,
            value: params?.data?.thu || '',
            rowIndex: params?.rowIndex || 0,
            field: params?.colDef?.field || '',
            checkboxChange: this.onCheckboxChange.bind(this)
          }),


          cellClass: this.getCellClass.bind(this)
        },
        {
          headerName: 'Friday', field: 'fri', sortable: true, resizable: true,
          cellRendererFramework: CheckboxCellComponent,
          cellRendererParams: (params) => ({
            isChecked: false,
            value: params?.data?.fri || '',
            rowIndex: params?.rowIndex || 0,
            field: params?.colDef?.field || '',
            checkboxChange: this.onCheckboxChange.bind(this)
          }),


          cellClass: this.getCellClass.bind(this)
        },
        {
          headerName: 'Saturday', field: 'sat', sortable: true, resizable: true,
          cellRendererFramework: CheckboxCellComponent,
          cellRendererParams: (params) => ({
            isChecked: false,
            value: params?.data?.sat || '',
            rowIndex: params?.rowIndex || 0,
            field: params?.colDef?.field || '',
            checkboxChange: this.onCheckboxChange.bind(this)
          }),


          cellClass: this.getCellClass.bind(this)
        }
      ],
      rowData: this.rowData,
    };
  }
  setEndDate(date){
    const futureDate = new Date(date);
    futureDate.setMonth(futureDate.getMonth() + 3);
    this.endDate = futureDate;
  }
  onGridReady(params: any) {
    this.gridApi = params.api;
    console.log('Grid is ready');
  }

  onCheckboxChange(checked: boolean, rowIndex: number, field: string, time: string): void {
    console.log("Received event in parent component:", checked, rowIndex, field, time);

    const dayName = this.getDayName(field);
    
    if (dayName) {
      if (checked) {
        this.addSchedule(dayName, time);
      } else {
        this.removeSchedule(dayName, time);
      }
    }

    console.log("selected schedule:", this.selectedSchedule);
}

// Helper function to map field to day name
getDayName(field: string): string | null {
  const dayMap = {
    sun: "Sunday",
    mon: "Monday",
    tue: "Tuesday",
    wed: "Wednesday",
    thu: "Thursday",
    fri: "Friday",
    sat: "Saturday",
  };
  return dayMap[field] || null;
}

// Function to add a schedule
addSchedule(day: string, time: string): void {
  this.selectedSchedule.push({ day, time });
}

// Function to remove a schedule
removeSchedule(day: string, time: string): void {
  this.selectedSchedule = this.selectedSchedule.filter(schedule => 
    schedule.day !== day || schedule.time !== time
  );
}




  onSelectionChanged() {
    const selectedRows = this.gridApi.getSelectedRows();
    console.log('Selected Rows:', selectedRows);
  }

  onRowClicked(event) {
    console.log("event:", event);
    event.stopPropagation();
  }
  selectedCells: Set<string> = new Set();

  onCellClicked(event: any) {
    console.log('Cell clicked:', event);
    event.stopPropagation();
    const cellId = `${event.rowIndex}-${event.colDef.field}`; // Unique identifier for the cell

    if (this.selectedCells.has(cellId)) {
      // Cell is already selected, so remove it
      this.selectedCells.delete(cellId);
      // Optional: Add a way to visually deselect the cell
      event.node.setDataValue(event.colDef.field, event.value); // Revert cell back to original value
    } else {
      // Cell is not selected, so add it
      this.selectedCells.add(cellId);
      // Optional: Add a way to visually select the cell
    }

    // Optionally, log the selected cells
    console.log('Selected Cells:', Array.from(this.selectedCells));

    // Refresh the grid to apply cell styles
    event.api.refreshCells();
  }

  getCellClass(params: any) {
    const cellId = `${params.rowIndex}-${params.colDef.field}`;
    return this.selectedCells.has(cellId) ? 'selected' : '';
  }



  showTeacherSlots(teacherData) {
    console.log("show teacher slots clicked", teacherData.availableDaysAndTimings)
    this.selectedTeacher = {
      teacherKey:{
        teacherID:teacherData.teacherID,
        timeStamp:teacherData.timeStamp.toString()
      },
      firstName:teacherData.firstName,
      lastName:teacherData.lastName
    }
    this.selectedSchedule = [];
    const tempRowData = [];
    this.showGrid = true
    const timeSlotsMap = {
      sun: [], mon: [], tue: [], wed: [], thu: [], fri: [], sat: []
    };
    teacherData.availableDaysAndTimings.forEach((dayInfo) => {
      dayInfo.timings.forEach(time => {
        switch (dayInfo.day) {
          case "Sunday": timeSlotsMap.sun.push(time); break;
          case "Monday": timeSlotsMap.mon.push(time); break;
          case "Tuesday": timeSlotsMap.tue.push(time); break;
          case "Wednesday": timeSlotsMap.wed.push(time); break;
          case "Thursday": timeSlotsMap.thu.push(time); break;
          case "Friday": timeSlotsMap.fri.push(time); break;
          case "Saturday": timeSlotsMap.sat.push(time); break;
        }
      });
    });
    const maxSlots = Math.max(
      timeSlotsMap.sun.length,
      timeSlotsMap.mon.length,
      timeSlotsMap.tue.length,
      timeSlotsMap.wed.length,
      timeSlotsMap.thu.length,
      timeSlotsMap.fri.length,
      timeSlotsMap.sat.length
    );
    for (let i = 0; i < maxSlots; i++) {
      tempRowData.push({
        sun: (i < timeSlotsMap.sun.length) ? timeSlotsMap.sun[i] : undefined,
        mon: (i < timeSlotsMap.mon.length) ? timeSlotsMap.mon[i] : undefined,
        tue: (i < timeSlotsMap.tue.length) ? timeSlotsMap.tue[i] : undefined,
        wed: (i < timeSlotsMap.wed.length) ? timeSlotsMap.wed[i] : undefined,
        thu: (i < timeSlotsMap.thu.length) ? timeSlotsMap.thu[i] : undefined,
        fri: (i < timeSlotsMap.fri.length) ? timeSlotsMap.fri[i] : undefined,
        sat: (i < timeSlotsMap.sat.length) ? timeSlotsMap.sat[i] : undefined
      });
    }

    this.rowData = tempRowData;
    this.gridOptions.rowData = this.rowData;
    this.gridApi?.setRowData(this.rowData);
    this.gridOptions?.api?.refreshCells();
    this.showGrid = true;
  }




  async getCourseDetails(): Promise<any> {
    try {
      const resp = await this.booking.getNewCourseDetails(this.courseId);
      return resp;
    } catch (err) {
      throw err;
    }

  }

  async getTeachers() {
    console.log("End date ----:", this.endDate, this.startDate);
    this.setEndDate(this.startDate)
    if (this.startDate && this.endDate&& this.courseKey && this.courseCategory) {
           
      if (this.startDate <= this.endDate) {
        console.log("inside if condition");
        const filter = {
          startDate: moment(this.startDate).format("YYYY-MM-DD"),
          endDate: moment(this.endDate).format("YYYY-MM-DD"),
          categoryKey: this.courseCategory,
          skillDevelopmentCourseKey: this.courseKey
        }
        try {
          console.log("inside try block")
          this.spinner.show();
          const teacherList = await this.booking.getTeacherByCategory(filter);
          console.log("teachersList:", teacherList);
          this.teachersList = teacherList?.data || []
          this.teachersList = this.teachersList.filter(teacher=>teacher.type && teacher.type ==='website');
        } catch (err) {
          console.log("error while fetching teacher list", err.message);
        } finally {
          this.spinner.hide()
        }

      } else {
        this.toast.error("Start date should be less than End date")
      }
    }
    console.log("function ends here");


  }
  onNext() {

    const tempEndDate = this.endDateCalculator.calculateEndDate(this.startDate,this.selectedSchedule, Number(this.coursePackage) );
    console.log("calculated endDate:", tempEndDate, this.startDate);
    const data = {
      teacherKey:this.selectedTeacher.teacherKey,
      teacherName:`${this.selectedTeacher.firstName} ${this.selectedTeacher.lastName}`,
      schedule:this.selectedSchedule,
      startDate:moment(this.startDate).format("YYYY-MM-DD"),
      endDate:tempEndDate,
    }

    localStorage.setItem("scheduleDetails",JSON.stringify(data));
    // console.log("handle next:", JSON.stringify(data,null,2));
    this.router.navigate([`/payment/create/${this.courseId}/${this.coursePackage}`]);
  }
}
