import { Injectable } from "@angular/core";
import { Observable, of } from 'rxjs';

@Injectable()
export class StudentFeedbackForm {
  constructor() {}

  getQuestions(): Observable<any[]> {
    const q = [
        {
            heading:"Understanding and Application:",
            title: "I feel confident in applying what I have learned in this program to real-world situations or problems.",
            selected_answer: [
              {
                option: "0",
                value: "0",
                label: "Not confident at all"
              },
              {
                option: "1",
                value: "1",
                label: ""
              },
              {
                option: "2",
                value: "2",
                label: ""
              },
              {
                option: "3",
                value: "3",
                label: ""
              },
              {
                option: "4",
                value: "4",
                label: ""
              },
              {
                option: "5",
                value: "5",
                label: "Extremely confident"
              }
            ],
            questionId: "1"
          },
          {
            heading:"Skill Development:",
            title: "I feel that my skills in this subject have significantly improved since starting the program.",
            selected_answer: [
              {
                option: "0",
                value: "0",
                label: "Not at all"
              },
              {
                option: "1",
                value: "1",
                label: ""
              },
              {
                option: "2",
                value: "2",
                label: ""
              },
              {
                option: "3",
                value: "3",
                label: ""
              },
              {
                option: "4",
                value: "4",
                label: ""
              },
              {
                option: "5",
                value: "5",
                label: "Improved significantly"
              }
            ],
            questionId: "2"
          },
          {
            heading:"Engagement and Challenge:",
            title: "I feel appropriately challenged by the material and activities in this program.",
            selected_answer: [
              {
                option: "0",
                value: "0",
                label: "Not challenged at all"
              },
              {
                option: "1",
                value: "1",
                label: ""
              },
              {
                option: "2",
                value: "2",
                label: ""
              },
              {
                option: "3",
                value: "3",
                label: ""
              },
              {
                option: "4",
                value: "4",
                label: ""
              },
              {
                option: "5",
                value: "5",
                label: "Very challenged in a positive way"
              }
            ],
            questionId: "3"
          },
          {
            heading:"Self-Reflection and Growth:",
            title: "This program is helping me achieve my personal learning goals effectively.",
            selected_answer: [
              {
                option: "0",
                value: "0",
                label: "Not at all"
              },
              {
                option: "1",
                value: "1",
                label: ""
              },
              {
                option: "2",
                value: "2",
                label: ""
              },
              {
                option: "3",
                value: "3",
                label: ""
              },
              {
                option: "4",
                value: "4",
                label: ""
              },
              {
                option: "5",
                value: "5",
                label: "Extremely well"
              }
            ],
            questionId: "4"
          },
    ];
    return of(q);
  }
}