<ngx-spinner bdColor="rgba(255,255,255,0.8)" color="rgb(74 72 72)"></ngx-spinner>

<div class="main-container">
    <!-- <h1 style="text-align: center; margin-bottom: 4rem; text-decoration: underline;">Schedule Your Session</h1>

    <div class="date-selector">
        <h3 style="text-align: center;">Select Approximate Dates to Schedule Your Sessions</h3>
        <div class="date-container">
            <mat-form-field class="mr-5">
                <input matInput [matDatepicker]="startDatePicker" placeholder="Choose Start Date"
                    [(ngModel)]="startDate" name="startDate" required (dateChange)="getTeachers()" [min]="today">
                <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #startDatePicker panelClass="custom-datepicker-popup"></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="mr-5">
                <input matInput [matDatepicker]="endDatePicker" placeholder="Choose End Date" [(ngModel)]="endDate"
                    name="endDate" required (dateChange)="getTeachers()" [min]="today">
                <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #endDatePicker></mat-datepicker>
            </mat-form-field>
        </div>
    </div> -->
    <h1 class="title">📅 {{'booking.schedule_heading' | translate}}</h1>

    <div class="date-selector">
        <!-- <h3 class="sub-title">{{'booking.select_date' | translate}}</h3> -->
        <div class="date-container">
            <mat-form-field class="custom-field">
                <input matInput [matDatepicker]="startDatePicker" placeholder="{{'booking.choose_start_date' | translate}}"
                    [(ngModel)]="startDate" name="startDate" required (dateChange)="getTeachers()" [min]="today">
                <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #startDatePicker panelClass="custom-datepicker-popup"></mat-datepicker>
            </mat-form-field>

            <!-- <mat-form-field class="custom-field">
                <input matInput [matDatepicker]="endDatePicker" placeholder="Choose End Date" [(ngModel)]="endDate"
                    name="endDate" required (dateChange)="getTeachers()" [min]="today">
                <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #endDatePicker></mat-datepicker>
            </mat-form-field> -->
        </div>
    </div>

    <div *ngIf="teachersList.length > 0 " class="teacher-container">
        <h3 style="text-align: center;">{{ 'booking.select_teacher' | translate}}</h3>


        <div class="teacher-card-container">
            <div *ngFor="let teacher of teachersList" class="teacher-card">
                <div class="teacher-image">
                    <img [src]="teacher.avatar" alt="Teacher Image">
                </div>
                <div class="teacher-details-container">
                    <div class="teacher-details">
                        <h3 class="teacher-name" style="text-align: center; width: 100%;">{{teacher.firstName}}
                            {{teacher.lastName}}</h3>
                        <p class="teacher-qualification"><span style="font-weight: 700;"> Qualification:</span>
                            {{teacher.highestQualification}}</p>
                        <p class="teacher-experience"><span style="font-weight: 700;">Experience:</span>
                            {{teacher.yearsOfExperience}}</p>
                        <p class="teacher-country">
                            <span style="font-weight: 700;">Course:</span> {{course}}
                        </p>
                    </div>
                </div>
                <div class="teacher-actions">
                    <button class="btn-message" (click)="showTeacherSlots(teacher)">{{'booking.available_slots' | translate}}</button>
                </div>
            </div>
            <div *ngIf="showGrid" class="popup-overlay">
                <div class="popup-content">
                    <button (click)="showGrid = false" class="close-button">x</button>
                    <p class="info">{{'booking.slot_message' | translate}}</p>
                    <ag-grid-angular *ngIf="rowData && rowData.length > 0"
                        style="width: 100%; height:85%; overflow:auto" class="ag-theme-alpine"
                        [gridOptions]="gridOptions" [defaultColDef]="{ flex: 1, minWidth: 100 }"
                        [suppressRowClickSelection]="true" (gridReady)="onGridReady($event)">
                    </ag-grid-angular>
                    <div
                        style="float: right; margin-top: 1rem; display: flex; align-items: center; justify-content: flex-end; padding-right: 2rem; width: 100%;">
                        <button class="btn-message" style="padding: 0.5rem 1rem; font-size: 1em;"
                            (click)="onNext()">{{'buttons.next' | translate}}</button>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>