import { SelectItem } from "../model/common";

export class TeacherInfo {
  teacherID: string = "";
  timeStamp: number;
  firstName: string = "";
  lastName: string = "";
  gender: string = "";
  email: string = "";
  dob: DoB = new DoB();
  dobStr: string;
  contactNo: string;
  address: string;
  city: string;
  country: string = "";

  highestQualification: string = "";
  yearsOfExperience: string = "";

  domainExpertisedInSelected: any[] = [];
  domainExpertisedIn: string[] = [];
  domainExpertisedInOther: string[] = [];

  curriculumExperienceInSelected: any[] = [];
  curriculumExperienceIn: string[] = [];
  curriculumExperienceInOther: string = "";

  gradeExperienceSelected: any[] = [];
  gradeExperience: string[] = [];
  gradeExperienceOther: string = "";

  skillDevelopmentCoursesSelected: any[] = [];

  languagesExperiencedInSelected: any[] = [];
  skillDevelopmentCourses: string[] = [];
  languagesExperiencedIn: string[] = [];

  availableDaysOfWeekSelected: any[] = [];
  availableDaysOfWeek: string[] = [];

  teachingHoursOnWeekday: number = 0;
  teachingHoursOnWeekend: number = 0;

  availableTimingsSelected: any[] = [];
  availableTimings: string[] = [];

  currentOccupationSelected: any[] = [];
  currentOccupation: string = "";
  currentOccupationOther: string = "";

  teachingBackground: string = "";
  howDidYouComeToKnowAboutTrusity: string = "";

  availableDaysAndTimings: AvailableDayAndTimings[] = [];
  domainExpertisedList: DomainExpertised[] = [];
  constructor() {
    this.availableDaysAndTimings = [];
    this.availableDaysAndTimings.push(new AvailableDayAndTimings("Sunday"));
    this.availableDaysAndTimings.push(new AvailableDayAndTimings("Monday"));
    this.availableDaysAndTimings.push(new AvailableDayAndTimings("Tuesday"));
    this.availableDaysAndTimings.push(new AvailableDayAndTimings("Wednesday"));
    this.availableDaysAndTimings.push(new AvailableDayAndTimings("Thursday"));
    this.availableDaysAndTimings.push(new AvailableDayAndTimings("Friday"));
    this.availableDaysAndTimings.push(new AvailableDayAndTimings("Saturday"));

    this.domainExpertisedList = [];
    this.domainExpertisedList.push(new DomainExpertised());
  }
  
}
export class DoB {
  day: string = "";
  month: string = "";
  year: string = "";

}

export class AvailableDayAndTimings {
  day: string = "";
  timings: string[] = [];
  constructor(day?) {
    if (day)
      this.day = day;
  }
}

export class DomainExpertised {

  domain: string = "";
  skillDevelopment: "";
  curriculum: "";
  curriculumGrade: "";
  curriculumSubject: "";
  learningSupport: "";
  learningSupportGrade: "";
  competitiveExams: "";
}