import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "../../environments/environment";
import { TeacherInfo, DoB } from '../model/teacher-info';
import { ResponseModel } from "../model/response-model";
import { CommonService } from "../services/common.service";


@Injectable({
  providedIn: 'root'
})
export class UsersService {
  baseUrl = environment.apiURL;
  adminUrl = environment.adminURL;
  public rememberMe;
  public userName;
  constructor(private httpClient: HttpClient,
  private commonService: CommonService) { }

  addOrUpdateTeacher(teacherInfo: TeacherInfo, formType: string): Promise<ResponseModel<any>> {
    return new Promise<ResponseModel<any>>((resolve, reject) => {
      let headers = new HttpHeaders();

      this.httpClient.post(this.adminUrl + `createTeacher/${formType}`,
        JSON.stringify(teacherInfo))
        .subscribe((res: ResponseModel<any>) => {
          resolve(res);
        }, (err) => {
          console.error("Error while addOrUpding Teacher", err);
          reject(err.error);
        });
    });
  }
  revertStatus(emailId: string): Promise<ResponseModel<any>> {
    var prm = JSON.stringify({ "email": emailId, "revertStatus": true });

    return new Promise<ResponseModel<any>>((resolve, reject) => {
      this.httpClient.post(this.baseUrl + "createTeacher/modify", prm)
        .subscribe((res: ResponseModel<any>) => {
          resolve(res);
        }, (err) => {
          console.error("Error while rejecting Teacher", err);
          reject(err.error);
        });
    });
  }
  rejectTeacher(emailId: string): Promise<ResponseModel<any>> {
    var prm = JSON.stringify({ "email": emailId, "rejectTeacher": true });

    return new Promise<ResponseModel<any>>((resolve, reject) => {
      this.httpClient.post(this.baseUrl + "createTeacher/modify", prm)
        .subscribe((res: ResponseModel<any>) => {
          resolve(res);
        }, (err) => {
          console.error("Error while rejecting Teacher", err);
          reject(err.error);
        });
    });
  }
  approveTeacher(emailId: string): Promise<ResponseModel<any>> {
    var prm = JSON.stringify({ "email": emailId, "approveTeacher": true });

    return new Promise<ResponseModel<any>>((resolve, reject) => {
      this.httpClient.post(this.baseUrl + "createTeacher/modify", prm)
        .subscribe((res: ResponseModel<any>) => {
          resolve(res);
        }, (err) => {
          console.error("Error while rejecting Teacher", err);
          reject(err.error);
        });
    });
  }

  getTeacher(emailId: string): Promise<ResponseModel<TeacherInfo>> {
    return new Promise<ResponseModel<TeacherInfo>>((resolve, reject) => {
      this.httpClient.get(this.baseUrl + `getUsers/teacher/${emailId}`)
        .subscribe((res: ResponseModel<TeacherInfo>) => {
          try {
            var data = res.data;;
            if (!data)
              resolve(res);

            data.domainExpertisedInSelected = this.commonService.getDominList()
              .filter(u => (data.domainExpertisedIn.filter(x => (x == u.value)).length > 0));

            data.gradeExperienceSelected = this.commonService.getGradeExperienceList()
              .filter(u => (data.gradeExperience.filter(x => (x == u.value)).length > 0));

            data.availableTimingsSelected = this.commonService.getAvailableTimingsList()
              .filter(u => (data.availableTimings.filter(x => (x == u.value)).length > 0));

            data.currentOccupationSelected = this.commonService.getCurrentOccupationList()
              .filter(u => u.value == data.currentOccupation);

            data.availableDaysOfWeekSelected = this.commonService.getAvailableDaysOfWeekList()
              .filter(u => (data.availableDaysOfWeek.filter(x => (x == u.value)).length > 0));

            data.languagesExperiencedInSelected = this.commonService.getLanguagesExperiencedInList()
              .filter(u => (data.languagesExperiencedIn.filter(x => (x == u.value)).length > 0));

            data.curriculumExperienceInSelected = this.commonService.getCurrentOccupationList()
              .filter(u => (data.curriculumExperienceIn.filter(x => (x == u.value)).length > 0));
            data.skillDevelopmentCoursesSelected = this.commonService.getSkillDevelopmentCoursesList()
              .filter(u => (data.skillDevelopmentCourses.filter(x => (x == u.value)).length > 0));

            res.data = data;
            resolve(res);
          } catch (e) {
            res.status = "D"
            res.msg = e.message;
            reject(res);
          }
        }, (err) => {
          console.error("Error while Fetching Teacher Info", err);
          reject(err.error);
        });
    });
  }

  getAllTeachers(approvalStatus: string): Promise<any> {
    return new Promise<ResponseModel<any>>((resolve, reject) => {
      // var objParam = `${JSON.stringify({ 'approvalStatus': approvalStatus })}`;
      this.httpClient.get(this.baseUrl + `getUsers/teacher/all?approvalStatus=${approvalStatus}`)
        .subscribe((res: ResponseModel<any>) => {
          resolve(res);
        }, (err) => {
          console.error("Error while fetching  getAllTeachers", err);
          reject(err.error);
        });
    });
  }
  getStudentsForSchedule(scheduledType?: string, enrollType?: string, filterObj?: any): Promise<any> {
    var objParam = `{}`;
    let forScheduling = false;
    if (typeof filterObj == "object") {
      objParam = `${JSON.stringify({ 'filter': filterObj })}`;
      forScheduling = true;
    }
    return new Promise<ResponseModel<any>>((resolve, reject) => {
      scheduledType = !(scheduledType) ? "pending" : scheduledType;
      enrollType = !(enrollType) ? "o3" : enrollType;

      this.httpClient.post(this.baseUrl + `getStudentsForScheduleClasses?scheduledType=${scheduledType}&enrollType=${enrollType}&forScheduling=${forScheduling}`, objParam)
        .subscribe((res: ResponseModel<any>) => {
          resolve(res);
        }, (err) => {
          console.error("Error while fetching  getAllTeachers", err);
          reject(err.error);
        });
    });
  }

  getAllStudents(status?: string): Promise<any> {

    return new Promise<ResponseModel<any>>((resolve, reject) => {
      status = (!status) ? "registered" : status;
      this.httpClient.get(this.baseUrl + `getAllStudent?status=${status}`)
        .subscribe((res: ResponseModel<any>) => {
          resolve(res);
        }, (err) => {
          console.error("Error while fetching  getAllStudents", err);
          reject(err.error);
        });
    });
  }

  getStudentByKey(studentID: string, timestamp: string): Promise<any> {

    return new Promise<ResponseModel<any>>((resolve, reject) => {
      this.httpClient.get(this.baseUrl + `getStudentByKey/${studentID}/${timestamp}`)
        .subscribe((res: ResponseModel<any>) => {
          resolve(res);
        }, (err) => {
          console.error("Error while fetching  getStudentByKey", err);
          reject(err.error);
        });
    });
  }
  getAllTeachersByCategory(data): Promise<any> {
    return new Promise<ResponseModel<any>>((resolve, reject) => {
      var objParam = `${JSON.stringify({ 'filter': data })}`;
      this.httpClient.post(this.baseUrl + `getTeachersByCategories`, objParam)
        .subscribe((res: ResponseModel<any>) => {
          resolve(res);
        }, (err) => {
          console.error("Error while fetching  getAllTeachersByCategory", err);
          reject(err.error);
        });
    });
  }

  getStudentEnrollMentInfo(enrollId, timeStamp) {
    return new Promise<ResponseModel<any>>((resolve, reject) => {
      // var objParam = `${JSON.stringify({ 'enrollId': enrollId, "timeStamp": timeStamp })}`;
      this.httpClient.get(this.baseUrl + `getStudentEnrollMentInfo?enrollId=${enrollId}&timeStamp=${timeStamp}`)
        .subscribe((res: ResponseModel<any>) => {
          resolve(res);
        }, (err) => {
          console.error("Error while fetching  getStudentEnrollMentInfo", err);
          reject(err.error);
        });
    });
  }

  updateStudentInfo(studentInfoData): Promise<ResponseModel<any>> {
    return new Promise<ResponseModel<any>>((resolve, reject) => {

      this.httpClient.post(this.baseUrl + `updateStudentProfile`,
        JSON.stringify(studentInfoData))
        .subscribe((res: ResponseModel<any>) => {
          resolve(res);
        }, (err) => {
          console.error("Error while updating StudentInfo ", err);
          reject(err.error);
        });
    });
  }

  getTeacherByKey(teacherID: string, timeStamp: string): Promise<any> {
    return new Promise<ResponseModel<any>>((resolve, reject) => {
      this.httpClient.get(this.baseUrl + `getTeacherByKey/${teacherID}/${timeStamp}`)
        .subscribe((res: ResponseModel<any>) => {
          resolve(res);
        }, (err) => {
          console.error("Error while fetching  getTeacherByKey", err);
          reject(err.error);
        });
    });
  }

  updateTeacherInfo(teacherInfoData): Promise<ResponseModel<any>> {
    return new Promise<ResponseModel<any>>((resolve, reject) => {

      this.httpClient.post(this.baseUrl + `updateTeacherInfo`,
        JSON.stringify(teacherInfoData))
        .subscribe((res: ResponseModel<any>) => {
          resolve(res);
        }, (err) => {
          console.error("Error while updating TeacherInfo ", err);
          reject(err.error);
        });
    });
  }

  registerUser(user: any): Promise<ResponseModel<any>> {
    return new Promise<ResponseModel<any>>((resolve, reject) => {
      this.httpClient.post(this.baseUrl + `register`,
        JSON.stringify(user))
        .subscribe((res: ResponseModel<any>) => {
          resolve(res);
        }, (err) => {
          console.error("Error while registering User", err);
          reject(err.error);
        });
    });
  }
}