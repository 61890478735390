import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  baseUrl = environment.apiURL;

  constructor(private http: HttpClient) {}

  async createPayment(paymentData: any): Promise<any> {
    try {
      const response = await this.http.post(this.baseUrl + "createPayment", paymentData).toPromise();
      return response;
    } catch (error) {
      console.error('Error occurred while creating payment:', error);
      throw error;
    }
  }

  async getPaymentDetailsByTransactionId(transactionId:any):Promise<any>{
    try {
      const response = await this.http.post(this.baseUrl + "getPaymentInfo", transactionId).toPromise();
      return response;
    } catch (error) {
      console.error('Error occurred while creating payment:', error);
      throw error;
    }
  }

  async getProductDetailsById(id:any):Promise<any>{
    try {
      const response = await this.http.post(this.baseUrl + "getProductDetails", id).toPromise();
      return response;
    } catch (error) {
      console.error('Error occurred while fetching product details:', error);
      throw error;
    }
  }

  async createStudentCreds(paymentId:string):Promise<any>{
    try {
      const response = await this.http.post(this.baseUrl + "createStudentCreds", JSON.stringify({paymentId})).toPromise();
      return response;
    } catch (error) {
      console.error('Error occurred while creating student creds:', error);
      throw error;
    }
  }

  async getStudentInfo(email:string):Promise<any>{
    try{
      const response = await this.http.post(this.baseUrl + "getStudentInfo", JSON.stringify({studentEmail:email})).toPromise();
      return response;
    }catch(err){
      throw err;
    }
  }

  // async getStudentDetailsFromPayment(paymentId):Promise<any>{
  //   try{
  //     const response = await 
  //   }catch(err){
  //     console.log
  //   }
  // }

  async checkPreScore(preScoreObject):Promise<any>{
    try{
      const res = await this.http.post(this.baseUrl+"checkTabbyPreScore", JSON.stringify(preScoreObject)).toPromise();
      return res;
    }catch(err){
      throw err;
    }
  }
}


