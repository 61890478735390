import { Injectable } from "@angular/core";
import { Observable, of } from 'rxjs';

@Injectable()
export class StdFormService {
  constructor() {}

  getQuestions(): Observable<any[]> {
    const q = [
      {
        title: "How much do you enjoy your sessions with your teacher?",
        selected_answer: [
          {
            option: "0",
            value: "0",
            label: "Poor"
          },
          {
            option: "1",
            value: "1",
            label: ""
          },
          {
            option: "2",
            value: "2",
            label: ""
          },
          {
            option: "3",
            value: "3",
            label: ""
          },
          {
            option: "4",
            value: "4",
            label: ""
          },
          {
            option: "5",
            value: "5",
            label: "Excellent"
          }
        ],
        questionId: "1"
      },
      {
        title: "Has the teaching helped you understand and improve in your subject?",
        selected_answer: [
          {
            option: "0",
            value: "0",
            label: "Poor"
          },
          {
            option: "1",
            value: "1",
            label: ""
          },
          {
            option: "2",
            value: "2",
            label: ""
          },
          {
            option: "3",
            value: "3",
            label: ""
          },
          {
            option: "4",
            value: "4",
            label: ""
          },
          {
            option: "5",
            value: "5",
            label: "Excellent"
          }
        ],
        questionId: "2"
      },
      {
        title: "Does your teacher help you with the things you find difficult?",
        selected_answer: [
          {
            option: "0",
            value: "0",
            label: "Poor"
          },
          {
            option: "1",
            value: "1",
            label: ""
          },
          {
            option: "2",
            value: "2",
            label: ""
          },
          {
            option: "3",
            value: "3",
            label: ""
          },
          {
            option: "4",
            value: "4",
            label: ""
          },
          {
            option: "5",
            value: "5",
            label: "Excellent"
          }
        ],
        questionId: "3"
      },
      {
        title: "Are you comfortable asking questions during your teaching sessions?",
        selected_answer: [
          {
            option: "0",
            value: "0",
            label: "Poor"
          },
          {
            option: "1",
            value: "1",
            label: ""
          },
          {
            option: "2",
            value: "2",
            label: ""
          },
          {
            option: "3",
            value: "3",
            label: ""
          },
          {
            option: "4",
            value: "4",
            label: ""
          },
          {
            option: "5",
            value: "5",
            label: "Excellent"
          }
        ],
        questionId: "4"
      },
      // {
      //   title: "5. Can your child easily rearrange shapes, patterns as required, such as in Rubik's cube, puzzles and lego?",
      //   selected_answer: [
      //     {
      //       option: "0",
      //       value: "0"
      //     },
      //     {
      //       option: "1",
      //       value: "1"
      //     },
      //     {
      //       option: "2",
      //       value: "2"
      //     },
      //     {
      //       option: "3",
      //       value: "3"
      //     },
      //     {
      //       option: "4",
      //       value: "4"
      //     },
      //     {
      //       option: "5",
      //       value: "5"
      //     }
      //   ],
      //   questionId: "5"
      // }
      ];
      return of(q);
    }
  }
