<ngx-spinner bdColor="rgba(255,255,255,0.8)" color="rgb(74 72 72)"></ngx-spinner>

<div class="form-container">
    <form *ngIf="!showOtp" #registrationForm="ngForm" (ngSubmit)="submitForm(registrationForm)" novalidate
        class="reg-form">

        <h2 style="text-align: center;">📋 Sign Up</h2>
        <label for="FirstName" [ngClass]="{'algn-label': isArabic()}">{{'register.first_name'|translate}}</label>
        <div class="error-container">
            <input type="text" id="FirstName" [(ngModel)]="firstName"
                placeholder="{{'register.enter_first_name'|translate}}" name="FirstName" required />
            <div *ngIf="registrationForm.submitted && registrationForm.controls.FirstName?.invalid" class="error">First
                name is
                required</div>
        </div>

        <label for="LastName" [ngClass]="{'algn-label': isArabic()}">{{'register.last_name'|translate}}</label>
        <div class="error-container">
            <input type="text" id="LastName" [(ngModel)]="lastName"
                placeholder="{{'register.enter_last_name'|translate}}" name="LastName" required />
            <div *ngIf="registrationForm.submitted && registrationForm.controls.LastName?.invalid" class="error">Last
                name
                is
                required</div>
        </div>

        <label for="Email" [ngClass]="{'algn-label': isArabic()}">{{'register.email'|translate}}</label>
        <div class="error-container">
            <input type="email" id="Email" [(ngModel)]="email" placeholder="{{'register.enter_email'|translate}}"
                name="Email" required #EmailControl="ngModel" email (change)="checkStudent(registrationForm)" />
            <div *ngIf="registrationForm.submitted && EmailControl.invalid && registrationForm.controls.Email?.invalid"
                class="error">
                Email is required and must be valid</div>
            <div *ngIf="studentExists" class="error">
                This student already exists, please use a different email.
            </div>
        </div>
        <label for="Phone" [ngClass]="{'algn-label': isArabic()}">{{'register.phone'|translate}}</label>
        <div class="error-container">
            <div id="phone-num">
                <select id="countryCode" class="form-control" [(ngModel)]="selectedCode" name="countryCode">
                    <option *ngFor="let country of countryCodes" [value]="country.countryCode">
                        {{ country.name }} ({{ country.countryCode }})
                    </option>
                </select>
                <input type="number" id="phone" [(ngModel)]="phone" placeholder="{{'register.enter_phone'|translate}}"
                    name="Phone" required minlength="9"/>
            </div>
            <div *ngIf="registrationForm.controls.Phone?.invalid && registrationForm.controls.touched" class="error">
                Phone number is
                required</div>
        </div>
        <label for="Phone" [ngClass]="{'algn-label': isArabic()}">{{'register.dob'|translate}}</label>
        <mat-form-field appearance="outline" style="width: 100%;">
            <!-- <mat-label class="dob-label">{{'register.dob'|translate}}</mat-label> -->
            <input 
                matInput 
                [matDatepicker]="picker" 
                placeholder="Enter BOB"
                [(ngModel)]="dob" 
                name="Dob" 
                required 
                (focus)="picker.open()"
                [max]="minDob" 
                [min]="maxDob"
            >
            <mat-datepicker-toggle matSuffix [for]="picker" style="height: 100%; align-items: center;"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <!-- <div *ngIf="!student.dob && showStudentSection" class="error">DOB is required</div> -->
            <div *ngIf="registrationForm.submitted && registrationForm.controls.Dob?.invalid" class="error">DOB
                is required</div>
        </mat-form-field>

        <label for="grade" [ngClass]="{'algn-label': isArabic()}">{{'register.grade'|translate}}</label>
        <div class="error-container">
            <input type="text" id="grade" [(ngModel)]="grade" placeholder="{{'register.enter_grade'|translate}}"
                name="Grade" required />
            <div *ngIf="registrationForm.submitted && registrationForm.controls.Grade?.invalid" class="error">
                Grade is required</div>
        </div>

        <label for="school" [ngClass]="{'algn-label': isArabic()}">{{'register.school'|translate}}</label>
        <div class="error-container">
            <input type="text" id="school" [(ngModel)]="school" placeholder="{{'register.enter_school'|translate}}"
                name="School" required />
            <div *ngIf="registrationForm.submitted && registrationForm.controls.School?.invalid" class="error">
                School is required</div>
        </div>
        <label for="password" [ngClass]="{'algn-label': isArabic()}">{{'register.password'|translate}}</label>
        <div class="error-container">
            <input type="password" id="password" [(ngModel)]="password"
                placeholder="{{'register.enter_password'|translate}}" name="Password" required minlength="8" />
            <div *ngIf="registrationForm.submitted && registrationForm.controls.Password?.invalid" class="error">
                Password is required</div>
            <div *ngIf="registrationForm.submitted && registrationForm.controls.Password?.invalid && password.length < 8" class="error">
                Password should have a minimum of 8 characters
            </div>
        </div>
        <div>
            <button type="submit" class="submit-btn">{{'buttons.register' |translate}}</button>
        </div>
    </form>

    <div *ngIf="showOtp" class="opt-container">
        <div class="otp-card">
            <h2> OTP</h2>
            <input type="number" id="otp" [(ngModel)]="otp" name="OTP" required />
            <button (click)="confirmOtp(otp)" class="submit-btn">Confirm</button>
        </div>
    </div>
</div>