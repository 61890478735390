import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  constructor(private translate: TranslateService) {}

  setLanguage(language: string): void {
    this.translate.use(language);
    localStorage.setItem('language', language);
  }

  getLanguage(): string {
    return this.translate.currentLang || localStorage.getItem('language') || 'English';
  }

  getTranslation(key: string): string {
    return this.translate.instant(key);
  }
  isArabic(): boolean {
    return this.getLanguage() === 'Arabic';
  }
}
